@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer {
  background: -webkit-linear-gradient(left, #9d57c6 30%, #720dac 60%);
  color: white;
  font-weight: bold;
  width: 100%;
  padding: 25px;
}
.footer a {
  color:white;
}
.footer a:hover {
  color: white !important;
}
.footer-text{
  color:white;
}
 
.footer-col {
  /* background: -webkit-linear-gradient(left, #9d57c6 30%, #720dac 60%); */

  /* background-color: #35aee2; */
}
 

.pre-scrollable {

}
.navbarbg > {
  background-color: purple !important;
}
h1 {
  font-size: 50px;
  color:black;
  
  font-weight: 800 !important;
}

.mint-text {
  color: white;
  font-size: 25px;
  font-weight: bold;
}
.gradient-text-2 {
  background: -webkit-linear-gradient(left, #ad29ad 30%, #b112d1 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
body{
  font-family: 'Roboto', sans-serif;
}

h2, h3, h4, p{
  margin-bottom:1rem;
}

h2{
font-size: 1.5rem;
font-weight: 300;
text-transform: uppercase;
}

h3{
  font-size: 2.5rem;
  font-weight: 500;
  font-style: italic;
}

h4{
  font-size: 1.5rem;
  font-weight: 500;
  font-style: italic;
}

p, ul li{
  font-size: 1.25rem;
  font-weight: 300;
}

ul li{
  font-size: 1.25rem;
  font-weight: 300;
  text-align:left;
}

.hero{
  margin:0!important;
  padding:0!important;
  height: 100vh;
  overflow: hidden;
}

.hero .layer{
  position: absolute!important;
  width: 100%;
  height: 100%;
  margin:0!important;
  padding:0!important;
  background-position: bottom center;
}

.hero .layer img{
  min-width:640px;
  min-height:360px;
  height: auto;
  overflow: hidden;
}

.top-layer{
  background-image:url(/static/media/1.b10fa7b3.png);
  background-size:100%;
  background-repeat: no-repeat;
}

.content{
background-color: #2e250c;
color:white;
}

h1{
  color:white;
}

@media screen and (max-width: 1350px){
  .hero, .hero .layer{
    max-height: 80vh;
  }
  .top-layer{
    max-height: 80vh;  }
}

@media screen and (max-width: 1024px){
  .hero, .hero .layer{
    max-height: 40vh;
  }
  .top-layer{
    max-height: 40vh;  }
    .hero .layer.mushroom img{
      bottom: 0;
      min-width:384px;
      min-height:216px;
    }
}

/*Content blocks*/

.content-row .text-holder{
  justify-content: center;
  display: flex;
  flex-direction: column;}

.block-title{
  padding: 2.5rem 1rem;
}

.content-block{
  padding: 1rem;
}

.block-holder{
  background-position: center bottom;
  background-size: 100%;
  background-repeat: no-repeat;
}
.block-introduction{
  background-image: url(/static/media/purple-bg.22806f50.png);
  padding-bottom: 18rem;
}

.block-triptalks{
  background-image: url(/static/media/5down.c0f50fc0.png);
  background-position: center top;
  padding-top: 18rem;
  padding-bottom: 6rem;
}

.block-mentoring{
  background-color: #925D3F;
  margin-top:-5px;
}

.block-rarity{
  background-image: url(/static/media/5.e80e0734.png);
  padding-top: 6rem;
  padding-bottom: 24rem;
}

.block-ape{
  background-color: #744E5E;
  max-width:100%;
  background-image: url(/static/media/ape-layer.499c2012.png);
  background-position: center bottom;
  min-height:80vh
}

.block-forest{
  background-color: #151002;
  padding-top:9rem;
  padding-bottom:9rem;
  background-image: url(/static/media/3.d7a03951.png);
}

.block-footer{
  background-color: #151002;
  padding-top:9rem;
  padding-bottom:2rem;
  background-image: url(/static/media/1.e974b94d.png);
  background-position: center top;
}

.block-ape{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom:4rem;
}

.block-mentoring img{
  max-width:400px!important;
  margin-bottom:1.5rem;
}

.block-mentoring .block-title{
  padding-top:0;
}
/*Components*/

.accordion-item {
    background-color: transparent;
    margin-bottom: 0.5rem;
}

.accordion-button, .accordion-button:not(.collapsed) {
  color: white;
  background: transparent;
  box-shadow:none;
  border-radius: 100px!important;
  border:2px solid white;
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  content: '-';
}

.accordion-button::after {
  background-image: none;
  content: '+';
  font-family: Roboto;
  font-weight: 400;
  font-size: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-button:focus{
  border-color: white;
  box-shadow: none;
}

.accordion-button{
  font-family: Roboto;
  font-weight: 300;
  font-size: 1.25rem;
  text-transform: uppercase;
}
.btn{
  border-radius: 100px;
  padding: 1rem 1.25rem;
  font-family: Roboto;
  font-weight: 700;
  font-size: 1.25rem;
  text-transform: uppercase;
  margin:4rem 0;
  cursor:pointer;
}

.btn-primary{
  background-color: #744E5E;
  color: #F4F4CD;
  border: 2px solid #744E5E;
}

.btn-primary:hover{
  color: white;
  background-color: transparent;
  border: 2px solid white;
}

.btn-primary.btn-mint:hover{
  background-color: #925D3F;
  color: #F4F4CD;
  border: 2px solid #925D3F;
}

.btn-light{
  color: white;
  background-color: transparent;
  width: 40%;
}

.btn-light:hover{
  background-color: #744E5E;
  color: #F4F4CD;
  border-color: #744E5E;
}

.creator-img{
  max-width:100%;
}

.creator-text{
  display:flex;
  flex-direction: column;
  justify-content: center;
}

.block-creators h5, .block-creators a{
  color:#8B7337;
  font-family: Roboto;
  font-weight: 700;
}

.block-creators .content-row{
  margin-bottom:1.5rem;
}
/*utility classes*/

.text-right{
  text-align: right;
}

.text-left{
  text-align: left;
}

.text-italic{
  font-style: italic;
}

.no-padding{
  padding:0;
}

.no-margin{
  margin:0;
}

/*Range slider*/

.slider-parent{
  position:relative;
}

/********** Range Input Styles **********/
/*Range Reset*/
input[type="range"] {
  -webkit-appearance: none;
   appearance: none;
   background: transparent;
   cursor: pointer;
   width: 100%;
}

/* Removes default focus */
input[type="range"]:focus {
 outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: transparent;
  border:2px solid white;
  border-radius: 1rem;
  height: 4px;  
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
 -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -8px; /* Centers thumb on the track */

  /*custom styles*/
  background-color: white;
  height: 1rem;
  width: 1rem;
  border-radius: 1rem;
}

/******** Firefox styles ********/
/* slider track */
input[type="range"]::-moz-range-track {
  background-color: transparent;
  border:2px solid white;
  border-radius: 1rem;
  height: 4px;  
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 0; /*Removes default border-radius that FF applies*/

  /*custom styles*/
  background-color: white;
  height: 1rem;
  width: 1rem;
  border-radius: 1rem;
}

.slider-text-holder{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Roboto;
  font-weight: 300;
  font-size:1.25rem;
}

.shroom-name, .shroom-number{
  font-style: italic;
  font-weight: 500;
}

.slider-parent{
  margin-top:1.5rem;
}

.rarity-img{
  width:100%;
  height:auto;
  border:1px solid white;
  border-radius: 2.5rem;
}

.block-rarity .btn{
  width:100%;
  margin: 1.5rem 0;
  cursor: default;
}

.shroom-spore{
  font-style: normal;
  font-weight: 700;
}

/*footer*/
.block-footer{
  color: #775F26;
}

.block-footer .nav-item a{
  text-transform: uppercase;
  color:#775F26;
  font-family: Roboto;
  font-weight:500;
  font-size: 1.25rem;
}

.block-footer .social-bar a{
  border-radius:1.5rem;
  height:1.5rem;
  width:1.5rem;
  margin:1.5rem 0.25rem;
  color: #151002;
  font-size:1rem;
  background-color:#775F26;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.block-footer-bottom {
  background-color: #080600;
  color: #5D4D1E;
}
.block-footer-bottom .block-title{
  padding:0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.block-footer-bottom .block-title p{
  padding:1.5rem 0;
  margin:0;
}

/*Header*/
.navbar {
  position: absolute;
  z-index: 9999;
  width: 100%;
}

.navbar .social-bar a{
  border-radius:1.5rem;
  height:1.5rem;
  width:1.5rem;
  margin:1.5rem 0.25rem;
  color: #d0b54f;
  font-size:1rem;
  background-color:#151002;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}


.block-creators .social-bar a{
  border-radius:1.5rem;
  height:1.5rem;
  width:1.5rem;
  margin:1.5rem 0.25rem;
  color: #2e250c;
  font-size:1rem;
  background-color:#775F26  ;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  flex-flow: column;
  z-index: 9999;
}

.navbar-collapse .nav-item{
  width: 9rem;
  background-color:#151002;
}

.navbar-toggler:focus{
  box-shadow: none;
}

.navbar-collapse .nav-item a{
  color:#d0b54f;
}

.navbar-light .navbar-toggler{
  border-width:0!important;
}

.react-parallax.layer.mushroom .react-parallax-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  z-index: 999;
}

.react-parallax.layer.mushroom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.block-mintbutton{
  display: flex;
  align-items: center;
  justify-content: center;
}

.block-mintbutton .btn{
  width: 350px;
  margin:0;
  margin-top: -218px;
  z-index: 9998;
  position: absolute;
}

/*Responsive corrections*/
.block-holder img{
  max-width: calc(100% - 2rem);
}

@media screen and (max-width:767px) {
  .block-holder .btn-light{
    width: 80%;
  }
  .block-holder{
    padding-top:4rem!important;
    padding-bottom:4rem!important;
  }
  .block-ape{
    padding-bottom:8rem!important;
  }
  .block-holder .col{
      flex: none;
      text-align:center!important;
      padding-top:0rem!important;
      padding-bottom:0rem!important;
  }
  .creator-text{
    text-align:center;
  }
  .block-mintbutton{
    padding-top:0rem!important;
    padding-bottom:0rem!important;
  }
  .block-mintbutton .btn{
    margin-top:0;
  }
  .block-creators{
    padding-top:0rem!important;
    padding-bottom:0rem!important;
  }
  .block-triptalks{
    padding-bottom:0rem!important;
  }
  .block-forest{
    padding-top:0rem!important;
  }
  .block-faq{
    padding-top:0rem!important;
  }
  .block-rarity .image-holder.col {
    padding: 0;
}
.block-creators img{
  max-width:200px;
}
.block-mentoring img{
  max-width:80%!important;
}
}

.btn-light.btn-spore:hover{
  background-color: transparent;
  color: white;
  border-color: white;
}
