body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer {
  background: -webkit-linear-gradient(left, #9d57c6 30%, #720dac 60%);
  color: white;
  font-weight: bold;
  width: 100%;
  padding: 25px;
}
.footer a {
  color:white;
}
.footer a:hover {
  color: white !important;
}
.footer-text{
  color:white;
}
 
.footer-col {
  /* background: -webkit-linear-gradient(left, #9d57c6 30%, #720dac 60%); */

  /* background-color: #35aee2; */
}
 

.pre-scrollable {

}
.navbarbg > {
  background-color: purple !important;
}
h1 {
  font-size: 50px;
  color:black;
  
  font-weight: 800 !important;
}

.mint-text {
  color: white;
  font-size: 25px;
  font-weight: bold;
}
.gradient-text-2 {
  background: -webkit-linear-gradient(left, #ad29ad 30%, #b112d1 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}